import React, { useState, useEffect } from "react";
import './usuariosAdministracion.css'
import { NavBar } from '../NavBarAdmin/navBar';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Table, Button, Form, Row, Col, InputGroup } from "react-bootstrap";
import { AiFillEdit, AiOutlinePlus, AiFillDelete, AiOutlineSearch } from 'react-icons/ai';
import { GrSend } from 'react-icons/gr';
import { BsCheckCircle, BsXCircle } from "react-icons/bs";
import { CrearUsuarioAdministrador } from "./crearModal";
import { ModificarUsuarioAdministrador } from "./modificarModal";


// HTTP Requests
import Axios from 'axios';

export const UsuariosAdministracion = () => {

    const [lista, setLista] = useState([]);
    const [listaSec, setListaSec] = useState([]);
    const [showNew, setShowNew] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    const [instituciones, setInstituciones] = useState([]);
    const [codigo, setCodigo] = useState('');
    const [elementos, setElementos] = useState(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '']);
    const [mensaje, setMensaje] = useState('');
    
  
    useEffect(()=>{
        Axios.get('/api/get/institucionesAdminSystem',{headers:{"x-access-token": localStorage.getItem('tokenAdminSys')}}).then(
            (response)=>{
                if (response.data.error){
                    toast.error('Ha ocurrido un error al enviar su solicitud');
                } else if(response.data.result) {
                    setInstituciones(response.data.result);
                }
            }
        );
    },[]);
    
    const getUsers = (code) => {
        Axios.get('/api/get/userByEntity/'+String(code),{headers:{"x-access-token": localStorage.getItem('tokenAdminSys')}}).then(
            (response) => {
                if (response.data.error){
                    toast.error('Ha ocurrido un error al enviar su solicitud');
                } else if(response.data.result) {
                    setLista(response.data.result);
                    setListaSec(response.data.result);
                }
            }
        );
    }

    const deleteUser = (code) => {
        Axios.post('/api/post/deleteUser/'+String(code), {id: code},{headers:{"x-access-token": localStorage.getItem('tokenAdminSys')}}).then(
            (response)=>{
                if (response.data.error){
                    toast.error('Error al elimianr al responsable');
                } else if (response.data.result) {
                    toast.success('Usuario eliminado');
                    Axios.get('/api/get/userByEntity/'+String(codigo),{headers:{"x-access-token": localStorage.getItem('tokenAdminSys')}}).then(
                        (responseGet) => {
                            if (responseGet.data.error){
                                toast.error('Ha ocurrido un error al enviar su solicitud');
                            } else if(responseGet.data.result) {
                                setLista(responseGet.data.result);
                                setListaSec(responseGet.data.result);
                            }
                        }
                    );
                } 
            }
        )
    }

    const sendCredentials = (codigo) => {
        Axios.post('/api/send/sendNewCredentials', {id: codigo},
        {headers:{"x-access-token": localStorage.getItem('tokenAdminSys')}}).then(
            (response)=>{
                if (response.data.error){
                    if (response.data.error.mensaje){
                        toast.error(response.data.error.mensaje);
                    }else{
                        toast.error('Error al cambiar credenciales');
                    }
                } else if (response.data.result) {
                    toast.success('Se ha enviado con exito las nuevas credenciales');
                } 
            }
        )
    }

    const showCredentials = (codigo, nombre) => {
        Axios.post('/api/update/showNewCredentials', {id: codigo},
        {headers:{"x-access-token": localStorage.getItem('tokenAdminSys')}}).then(
            (response)=>{
                if (response.data.error){
                    if (response.data.error.mensaje){
                        toast.error(response.data.error.mensaje);
                    }else{
                        toast.error('Error al cambiar credenciales');
                    }
                } else if (response.data.result) {
                    toast.success('Se ha modificaco con exito la contraseña.');
                    window.scrollTo(0,0);
                    setMensaje(`La contraseña nueva de ${nombre} es ${response.data.result}. (este mensaje aparecerá solo por 60 segundos)`);
                    setTimeout(function(){
                        setMensaje('');
                    }, 60000);
                } 
            }
        )
    }

    const busquedaUsuario = (listaInfo) => {
        const input1 = document.getElementById('input-1').value;
        const input2 = document.getElementById('input-2').value;
        const input3 = document.getElementById('input-3').value;
        const input4 = document.getElementById('input-1-sign').value;
        let tmpArray = []
        if (String(input1) === '' && String(input2) === '' && String(input3) === '' && String(input4) === ''){
            return listaInfo
        } else {
            const indexes = listaInfo.map(
                    (element, index) => {
                            let flag = false;

                            if (String(input1) !== ''){
                                if(element.NOMBRE_COMPLETO_JUNTO.toLowerCase().includes(input1.toLowerCase())){
                                    flag = true;
                                } else {
                                    return -1
                                }
                            }

                            if (String(input2) !== ''){
                                if(element.CORREO_ELECTRONICO.toLowerCase().includes(input2.toLowerCase())){
                                    flag = true;
                                } else {
                                    return -1
                                }
                            }

                            if (String(input3) !== ''){
                                if(element.USUARIO_WEB.toLowerCase().includes(input3.toLowerCase())){
                                    flag = true;
                                } else {
                                    return -1
                                }
                            }

                            if (String(input4) !== ''){
                                try {
                                    if(String(element.USUARIO_VALIDO) === input4){
                                        flag = true;
                                    } else {
                                        return -1
                                    }
                                } catch (error) {
                                    return -1
                                }
                            }

                            if(flag){
                                return index
                            }
                            
                        }
                    ).filter(value => value >= 0)
            for (let i of indexes) {
                tmpArray.push(listaInfo[i])
            }
            return tmpArray
        }
    }

    return(
        <>
            <NavBar/>
            <div className="container-usuario-administracion">
                <h3 className="blog-title">Persona</h3>
                <p className="lead blog-description">Administración de personas</p>
                <hr/>
                <Button variant="success" size="sm" onClick={()=>{setShowNew(true);}}><AiOutlinePlus/> Agregar nuevo usuario</Button>
                <p/>
                <Row className="mb-1">
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                        <Form.Label>Seleccione la institución del cual quiere detalles</Form.Label>
                        <Form.Select onChange={(e)=>{
                            if(e.target.value !== ''){
                                getUsers(e.target.value);
                                setCodigo(e.target.value);
                            } else {
                                setLista([]);
                                setListaSec([]);
                            }
                        }}
                        >
                            <option value=''/>
                            {
                                instituciones.map((value)=>{
                                    return(
                                        <option key={value.CODIGO} value={value.CODIGO}>
                                            {value.NOMBRE}
                                        </option>
                                    )
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                </Row>
                <p/>
                <p className="text-danger ng-scope">{mensaje}</p>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Opción</th>
                            <th>Nombre completo</th>
                            <th>Correo Electronico</th>
                            <th>Usuario</th>
                            <th>Estado</th>
                        </tr>
                        <tr>
                            <td>Herramientas de busqueda</td>
                            <td>
                                <InputGroup className="search-input">
                                    <Button 
                                        variant="outline-secondary"
                                        onClick={()=>{
                                            setListaSec(busquedaUsuario(lista));
                                        }}
                                    >
                                        <AiOutlineSearch/>
                                    </Button>
                                    <Form.Control
                                        onChange={()=>{
                                            setListaSec(busquedaUsuario(lista));
                                        }}
                                        id="input-1"
                                        type="text"
                                        placeholder="busqueda por nombre"
                                    />
                                </InputGroup>
                            </td>
                            <td>
                                <InputGroup className="search-input">
                                    <Button 
                                        variant="outline-secondary"
                                        onClick={()=>{
                                            setListaSec(busquedaUsuario(lista));
                                        }}
                                    >
                                        <AiOutlineSearch/>
                                    </Button>
                                    <Form.Control
                                        onChange={()=>{
                                            setListaSec(busquedaUsuario(lista));
                                        }}
                                        id="input-2"
                                        type="text"
                                        placeholder="busqueda por correo"
                                    />
                                </InputGroup>
                            </td>
                            <td>
                                <InputGroup className="search-input">
                                    <Button 
                                        variant="outline-secondary"
                                        onClick={()=>{
                                            setListaSec(busquedaUsuario(lista));
                                        }}
                                    >
                                        <AiOutlineSearch/>
                                    </Button>
                                    <Form.Control
                                        onChange={()=>{
                                            setListaSec(busquedaUsuario(lista));
                                        }}
                                        id="input-3"
                                        type="text"
                                        placeholder="busqueda por usuario web"
                                    />
                                </InputGroup>
                            </td>
                            <td>
                                <InputGroup className="search-input">
                                    <Button 
                                        variant="outline-secondary"
                                        onClick={()=>{
                                            setListaSec(busquedaUsuario(lista));
                                        }}
                                    >
                                        <AiOutlineSearch/>
                                    </Button>
                                    <Form.Select 
                                            variant="outline-secondary"
                                            id="input-1-sign"
                                            onChange={()=>{
                                                setListaSec(busquedaUsuario(lista));
                                            }}
                                        >
                                            <option value='' ></option>
                                            <option value='0' >{'no valido'}</option>
                                            <option value='1' >{'valido'}</option>
                                    </Form.Select>
                                </InputGroup>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            listaSec.map((value)=>{
                                return(
                                    <tr key={value.CODIGO_PERSONA}>
                                        <td>
                                            <Button className="rolUsuarioButton"  variant='warning' size='sm'
                                                onClick={()=>{        
                                                    setShowUpdate(true);
                                                    setElementos([
                                                        value.CODIGO_PERSONA, value.PRIMER_NOMBRE, value.SEGUNDO_NOMBRE,
                                                        value.TERCER_NOMBRE, value.PRIMER_APELLIDO, value.SEGUNDO_APELLIDO,
                                                        value.APELLIDO_CASADA, value.CORREO_ELECTRONICO, value.FECHA_NACIMIENTO, 
                                                        value.SEXO, value.USUARIO_WEB, value.USUARIO_VALIDO, 
                                                        value.maestro, value.codigo_direccion, value.codigo_entidad
                                                    ])
                                                }}
                                            > <AiFillEdit className="svgButton"/> Modificar </Button>
                                            <Button className="rolUsuarioButton"  variant='danger'  size='sm'
                                                onClick={()=>{
                                                    deleteUser(value.CODIGO_PERSONA);
                                                }}
                                            > <AiFillDelete className="svgButton"/> Eliminar</Button>
                                            <Button className="rolUsuarioButton"  variant='info'  size='sm'
                                                onClick={()=>{
                                                    sendCredentials(value.CODIGO_PERSONA);
                                                }}
                                            > <GrSend className="svgButton"/> Enviar credenciales</Button>
                                            <Button className="rolUsuarioButton"  variant='secondary'  size='sm'
                                                onClick={()=>{
                                                    showCredentials(value.CODIGO_PERSONA, value.NOMBRE_COMPLETO_JUNTO);
                                                }}
                                            >Cambiar y mostrar credenciales</Button>
                                        </td>
                                        <td>
                                            {value.NOMBRE_COMPLETO_JUNTO}
                                        </td>
                                        <td>
                                            {value.CORREO_ELECTRONICO}
                                        </td>
                                        <td>
                                            {value.USUARIO_WEB}
                                        </td>
                                        <td>
                                            {
                                                (value.USUARIO_VALIDO === 1)?
                                                <p className="text-success"><BsCheckCircle/> Valido</p>:
                                                <p className="text-danger"><BsXCircle/> No valido</p>
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
            <CrearUsuarioAdministrador showNew={showNew} setShowNew={setShowNew} instituciones={instituciones} />
            <ModificarUsuarioAdministrador showUpdate={showUpdate} setShowUpdate={setShowUpdate} instituciones={instituciones} 
            elementos={elementos} codigo={codigo} getUsers={getUsers}/>
        </>
    );

}