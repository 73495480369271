import React, { useEffect, useState } from 'react'
import { Stack } from '@mui/material'
import { Table } from 'react-bootstrap'
import Axios from 'axios'
import FilaRoles from './FilaRoles'

function RolesViews(props) {

    const [lista, setLista] = useState([])
    const [roles, setRoles] = useState([])

    useEffect(() => {
        Axios.get('/api/get/useradminpersonas', { headers: { "x-access-token": localStorage.getItem('token') } })
            .then((response) => {
                setLista(response.data.result)
                Axios.get('/api/get/adminusuariolistaroles', { headers: { "x-access-token": localStorage.getItem('token') } })
                    .then((responseRoles) => {
                        setRoles(responseRoles.data.result)
                    })
            })
    }, [props.refresh])

    return (
        <>
            <Stack spacing={1} >
                <Table striped bordered hover responsive>
                    <thead >
                        <tr>
                            <th>
                                No.
                            </th>
                            <th>
                                Acciones
                            </th>
                            <th>
                                Nombre de la persona
                            </th>
                            <th>
                                Roles
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            lista.map((persona, index) => {
                                return (
                                    (persona) ?
                                        <FilaRoles indice={index} persona={persona} id={persona.USUARIO_WEB} roles={roles} />
                                        : <></>
                                )
                            })
                        }
                    </tbody>
                </Table>

            </Stack>
        </>
    )
}

export default RolesViews