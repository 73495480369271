import React from "react";
import { Login } from './User/Login/login';
import ReportesView from "./User/Reportes/ReportesView.jsx";
import { Home } from './User/Home/home';
import { ResponsablesView } from './User/Responsables/responsablesView';
import { RiesgosRiesgoView } from './User/Riesgos/riesgosRiesgoView';
import { DependenciasView } from './User/Dependencias/depedenciasView'
import { TipoObjetivoView } from './User/TipoObjetivo/tipoObjetivoView'
import { ObjetivosView } from './User/Objetivos/objetivosView'
import { PeriodosView } from './User/Periodo/periodosView'
import { ObjetivoPeriodoView } from './User/ObjetivoPeriodo/objetivoPeriodoView'
import { ObjetivoAreaView } from './User/ObjetivoArea/objetivoAreaView'
import { PuestoView } from './User/Puesto/puestoView'
import { AreasView } from './User/Dependencias/areasView'
import { Autorizacion } from "./User/Autorizacion/autorizacion";
import { RecContra } from "./RecuperarContra/recContra";
import { PageNotFound } from './PageNotFound/pageNotFound'
import { LoginAdminSystem } from "./adminSystem/LoginAdmin/login";
import { HomeAdminSys } from "./adminSystem/HomeAdmin/home";
import { RolUsuario } from "./adminSystem/RolUsuario/rolUsuario";
import { UsuariosAdministracion } from "./adminSystem/Usuario/usuariosAdministracion";
import { Instituciones } from "./adminSystem/Instituciones/instituciones";
import { LimiteUsuarios } from "./adminSystem/LimiteUsuarios/limiteUsuarios";
import Historial from "./adminSystem/Historial/Historial";
import MatrizContinuidad from "./User/MatrizContinuidad/MatrizContinuidad";
import AdminView from "./User/Admin/AdminView";
import Perfil from "./User/Perfil/Perfil";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CantidadUsuarios from "./adminSystem/Cantidad/CantidadUsuarios";
import PlanesAccion from "./User/PlanesAccion/PlanesAccion";
import ChatAssistant from './User/ChatAsistente/ChatAsistente'; // Importa el componente de chat
import {RiesgosRiesgoPeriodoView} from './User/RiesgoPeriodo/riesgoPeriodo';
import './styles/global.scss'; // Asegúrate de importar los estilos globales

function App() {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<PageNotFound />} />
        {localStorage.getItem("token") !== null ? (
          <>
            <Route path='/' exact element={<Home />} />
            <Route path='login' exact element={<Login />} />
            <Route path='matrizcontinuidad' exact element={<MatrizContinuidad />} />
            <Route path='dependencias' exact element={<DependenciasView />} />
            <Route path='tiposbjetivos' exact element={<TipoObjetivoView />} />
            <Route path='objetivos' exact element={<ObjetivosView />} />
            <Route path='periodos' exact element={<PeriodosView />} />
            <Route path='objetivo-periodo' exact element={<ObjetivoPeriodoView />} />
            <Route path='objetivo-area' exact element={<ObjetivoAreaView />} />
            <Route path='puestos' exact element={<PuestoView />} />
            <Route path='areas' exact element={<AreasView />} />
            <Route path='responsables' exact element={<ResponsablesView />} />
            <Route path='riesgos' exact element={<RiesgosRiesgoView />} />
            <Route path='reportes' exact element={<ReportesView />} />
            <Route path='autorizacion' exact element={<Autorizacion />} />
            <Route path='administracion' exact element={<AdminView />} />
            <Route path='planesaccion' exact element={<PlanesAccion />} />
            <Route path='perfil' exact element={<Perfil />} />
            <Route path='riesgo-periodo' exact element={<RiesgosRiesgoPeriodoView />} />
          </>
        ) : (
          <>
          </>
        )}
        {localStorage.getItem("tokenAdminSys") !== null ? (
          <>
            <Route path='/' exact element={<HomeAdminSys />} />
            <Route path='rolUsuario' exact element={<RolUsuario />} />
            <Route path='Historial' exact element={<Historial />} />
            <Route path='loginAdminSystem' exact element={<LoginAdminSystem />} />
            <Route path='login' exact element={<Login />} />
            <Route path='usuario' exact element={<UsuariosAdministracion />} />
            <Route path='institucion' exact element={<Instituciones />} />
            <Route path="controlUsuarios" exact element={<LimiteUsuarios />} />
            <Route path="cantidadUsuarios" exact element={<CantidadUsuarios />} />
          </>
        ) : (
          <>
          </>
        )}
        {localStorage.getItem("tokenAdminSys") === null && localStorage.getItem("token") === null && (
          <Route path='/' exact element={<Login />} />
        )}
        <Route path="loginAdminSystem" exact element={<LoginAdminSystem />} />
        <Route path="recuperar" exact element={<RecContra />} />
      </Routes>
      <ChatAssistant /> 
    </Router>
  );
}

export default App;
