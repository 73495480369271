import React, { useState, useEffect } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import Axios from 'axios'
import { Form, Button } from 'react-bootstrap'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

function CambiarLogo() {

    const [path, setPath] = useState('')
    const [fileElement, setFileElement] = useState('')

    useEffect(() => {
        refreshLogo()
    }, [])

    const refreshLogo = () => {
        Axios.get('/api/get/image-url', { headers: { "x-access-token": localStorage.getItem('token') } })
            .then((response) => {
                setPath(response.data.result[0].path)
            })
    }

    const submitDocument = () => {
        try {
            if (fileElement.files.length > 0) {
                let file = fileElement.files[0];
                let formData = new FormData();
                formData.set('files', file);
                Axios.post('/api/post/update-image-logo', formData,
                    {
                        headers: {
                            "x-access-token": localStorage.getItem('token'),
                            "Content-Type": "multipart/form-data",
                        },
                    }).then((response) => {
                        if (response.data.error) {
                            toast.error(response.data.message);
                        } else {
                            toast.success(response.data.message);
                            refreshLogo()
                        }
                    });
            } else {
                toast.error(`Elija un documento para subir con formato png o jpg`)
            }
        } catch (error) { toast.error('Error') }
    }

    return (
        <Stack spacing={2}>
            <Typography variant='h5' color='black'>
                En esta sección usted puede editar el logo que aparecerá dentro de sus reportes.
            </Typography>
            <img src={(path) ? 'https://monitorderiesgo.com/Pictures/' + path : ''} alt="Logo"></img>
            <Form.Control id={'subida'} type="file"
                onChange={() => {
                    try {
                        setFileElement(document.getElementById('subida'));
                    } catch (error) {
                        setFileElement('');
                    }
                }}
            />
            <Box>
                <Button size="sm" variant="success" onClick={submitDocument}>Cambiar</Button>
            </Box>
        </Stack>
    )
}

export default CambiarLogo