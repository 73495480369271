import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Table, OverlayTrigger, Tooltip, InputGroup } from 'react-bootstrap';
import { AiOutlinePlus, AiFillEdit, AiFillDelete, AiTwotoneAppstore, AiOutlineSearch } from 'react-icons/ai';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import '../Dependencias/formulario.css'
import PopUpBorrar from '../../Components/popUpBorrar'

// HTTP Requests
import Axios from 'axios';

toast.configure()

export const TipoObjetivo = (props) => {

    /**
     * Lista de dependencias
     */
    const [listaPrinTipObj, setListaPrinTipObj] = useState([]);
    const [listaTipObj, setListaTipObj] = useState([]);


    const [listaEliminar, setListaEliminar] = useState([]);
    
    /*modal del popup borrar */
    const [id, setId] = useState(null);
    const [descripcionTipObj, setDescripcionTipObj] = useState(null);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState(null);

    // Handle the displaying of the modal based on type and id
    const showDeleteModal = (id,nombre) => {
        setId(id);
        setDescripcionTipObj(nombre)
        setDeleteMessage(`¿Está seguro de borrar el tipo de objetivo ${nombre} ?`);
        setDisplayConfirmationModal(true);
    };

    // Hide the modal
    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };

    const [showState, setShowState] = useState(false);
    const [showStateUpdate, setShowStateUpdate] = useState(false);
    const [codigoEntidad, setCodigoEntidad] = useState('');

    const busquedaTipObj = (listaInfo) => {
        const input1 = document.getElementById('input-1').value;
        let tmpArray = []
        if (String(input1) === ''){
            return listaInfo
        } else {
            const indexes = listaInfo.map(
                    (element, index) => {
                            let flag = false;

                            if (String(input1) !== ''){
                                if(element.CODIGO.toLowerCase().includes(input1.toLowerCase())){
                                    flag = true;
                                } else {
                                    return -1
                                }
                            }

                            if(flag){
                                return index
                            }
                            
                        }
                    ).filter(value => value >= 0)
            for (let i of indexes) {
                tmpArray.push(listaInfo[i])
            }
            return tmpArray
        }
    }

    const refreshTipObj = (param) => {
        Axios.get("/api/get/tiposObjetivos",{headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=> {
            setListaPrinTipObj(response.data);
            setListaTipObj(response.data);
        });
    }

    const submitTipObj = () => {
        if(props.idTipObj !== '' && props.descrTipObj !== ''){
            Axios.post("/api/insert/tipoObjetivo", {
                idTipObj: props.idTipObj,
                descrTipObj: props.descrTipObj, 
            },{headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=>{
                if(response.data.result){
                    props.setIdTipObj('')
                    props.setDescrTipObj('')
                    setShowState(false);
                    refreshTipObj(codigoEntidad)
                }else{
                    toast.error('Error al guardar tipo de objetivo');
                }
            });
        } else {
            toast.error('Llene todos los campos')
        }
    };

 
    useEffect(() => {
        Axios.get('/api/various/getEntity', 
            {headers:{"x-access-token": localStorage.getItem('token')}}).then((response) =>{
                setCodigoEntidad(response.data[0].codigo_entidad);
                refreshTipObj(response.data[0].codigo_entidad)
        });
    }, []);

  
    const deleteTipObj = (codigo) => {
        Axios.delete('/api/delete/tipoObjetivo/' + String(codigo), {headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=>{
            if (response.data.result === 'Error'){
              toast.error('Error al eliminar tipo de objetivo: ' + descripcionTipObj)
            } else {
                toast.info('Tipo de objetivo eliminado con éxito: ' + descripcionTipObj)
                refreshTipObj(codigoEntidad)
            }
        });
        setDisplayConfirmationModal(false);
    };
  
    
    const updateTipObj = () => {
        if(props.idTipObj !== '' && props.siglasTipObj !== ''){
            Axios.put('/api/update/tipoObjetivo/'+String(props.idTipObj), {
                idTipObj: props.idTipObj,
                descrTipObj: props.descrTipObj, 
                },{headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=>{
                    if (response.data.result === 'Error'){
                    toast.error('Error al actualizar el tipo de objetivo: ' + props.idTipObj)
                    } else {
                        refreshTipObj(codigoEntidad)
                    }
            });
        } else {
            toast.error('Ingrese todos los campos')
        }
    };

    return (
        <div className="container">
            {/**
             * Titulos principales de la página
             */}
            <h2>Tipos de Objetivos</h2 >
            <p className="lead blog-description">Tipos de objetivos de la organización</p>
            
            {/**
             * Botón para crear nueva dependencia
             */}
            <Button size='sm' variant="success" className="nueva-dependencia" onClick={() => {
                props.setIdTipObj('');
                props.setDescrTipObj('');
                setShowState(true);}}>
                <AiOutlinePlus/> Nuevo tipo de objetivo
            </Button>
            {/** 
             * Primer Modal
             * Este modal es para el formulario de una nueva dependencia
            */}
            <Modal show={showState} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Agregar tipo de objetivo
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Código</Form.Label>
                            <Form.Control onChange={(e)=> {props.setIdTipObj(e.target.value);}} placeholder="Código" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Descripción del tipo de objetivo</Form.Label>
                            <Form.Control onChange={(e)=> {props.setDescrTipObj(e.target.value)}} placeholder="Descripción del tipo de objetivo" />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {submitTipObj();}}>Guardar</Button>
                    <Button variant='secondary' onClick={()=>{setShowState(false);}}>Cancelar</Button>
                </Modal.Footer>
            </Modal>
            <p/>
            {/**
             * Esta tabla es para hacer display de todos los tipos de objetivo existentes
             */}
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Opción</th>
                        <th>Código</th>
                        <th>Descripción</th>
                    </tr>
                    <tr>
                        <td>Herramientas de búsqueda</td>
                        <td>
                            <InputGroup className="search-input">
                                <Button 
                                    variant="outline-secondary"
                                    onClick={()=>{
                                        setListaTipObj(busquedaTipObj(listaPrinTipObj));
                                    }}
                                >
                                    <AiOutlineSearch/>
                                </Button>
                                <Form.Control
                                    onChange={()=>{
                                        setListaTipObj(busquedaTipObj(listaPrinTipObj));
                                    }}
                                    id="input-1"
                                    type="text"
                                    placeholder="búsqueda por código"
                                />
                            </InputGroup>
                        </td>
                        <td>
                            <InputGroup className="search-input">
                                <Button 
                                    variant="outline-secondary"
                                    onClick={()=>{
                                        setListaTipObj(busquedaTipObj(listaPrinTipObj));
                                    }}
                                >
                                    <AiOutlineSearch/>
                                </Button>
                                <Form.Control
                                    onChange={()=>{
                                        setListaTipObj(busquedaTipObj(listaPrinTipObj));
                                    }}
                                    id="input-2"
                                    type="text"
                                    placeholder="búsqueda por descripción"
                                />
                            </InputGroup>
                        </td>
                    </tr> 
                </thead>
                <tbody>
                    {listaTipObj.map((value) => {
                        return (
                        <tr key={value.CODIGO}>
                            <td>
                            <Button className="segundo-boton" size="sm" variant="info" onClick={() => {
                                props.setIdTipObj(value.CODIGO);
                                props.setDescrTipObj(value.DESCRIPCION);
                                setShowStateUpdate(true);
                                }}>
                                    <AiFillEdit/>    Editar
                            </Button>
                            {
                                (props.usuarioWeb === value.USUARIO_CREACION)?
                                    <Button className="segundo-boton" size="sm" variant="danger" 
                                    onClick={() => showDeleteModal(value.CODIGO,value.DESCRIPCION)}
                                    >
                                        <AiFillDelete/>    Eliminar
                                    </Button> : 
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={<Tooltip id="button-tooltip-1">No tiene autorización para eliminar este tipo de objetivo</Tooltip>}
                                    >
                                        <span>
                                            <Button className="segundo-boton" size="sm" variant="danger" disabled>
                                                <AiFillDelete/>    Eliminar
                                            </Button>
                                        </span>
                                    </OverlayTrigger>
                                    
                                
                            }
                            </td>
                            <td>{value.CODIGO}</td>
                            <td>{value.DESCRIPCION}</td>
                        </tr>
                        ); 
                    })}
                </tbody>
            </Table>
            <Modal show={showStateUpdate} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Editar tipo de objetivo
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Código</Form.Label>
                            <Form.Control disabled onChange={(e)=> {props.setIdTipObj(e.target.value);}} placeholder="Código" defaultValue={props.idTipObj}/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Descripción</Form.Label>
                            <Form.Control onChange={(e)=> {props.setDescrTipObj(e.target.value)}} placeholder="Descripción" defaultValue={props.descrTipObj}/>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {updateTipObj();setShowStateUpdate(false);}}>Guardar</Button>
                    <Button variant='secondary' onClick={()=>{
                        setShowStateUpdate(false);
                        props.setDescrTipObj('');
                        props.setIdTipObj('');}}>Cancelar</Button>
                </Modal.Footer>
            </Modal>        
            
            <PopUpBorrar showModal={displayConfirmationModal}
                confirmModal={deleteTipObj} hideModal={hideConfirmationModal}
                id={id} message={deleteMessage} />   
        </div>
    );
}    

