import React, { useState } from "react";
import { Button, Form } from 'react-bootstrap';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// HTTP Requests
import Axios from 'axios';

export const UploadFile = (props) => {

    const [fileElement, setFileElement] = useState('');
    const [flag, setFlag] = useState(false)

    const submitDocument = () => {
        try {
            if (props.maxSize){
                // check if user had selected a file and is less than 35000KB
                if (fileElement.files.length > 0 && fileElement.files[0].size < props.maxSize+1) {
                    let file = fileElement.files[0];
                    let formData = new FormData();
                    formData.set('files', file);
                    formData.set('id', props.codigo);
                    if (props.oldPath){
                        formData.set('path', props.oldPath);
                    }
                    Axios.post(props.URL, formData,
                    {
                        headers: {
                            "x-access-token": localStorage.getItem('token'),
                            "Content-Type": "multipart/form-data",
                        },
                    }).then((response) => {
                        setFlag(false)
                        if (response.data.error){
                            toast.error(response.data.message);
                        } else {
                            toast.success(response.data.message);
                            props.refresh();
                        }
                    }); 
                } else {
                    setFlag(false)
                    toast.error(`Elija un documento para subir que ocupe menos de 5MB y que sea el tipo correcto`)
                }
            } else {
                // logos
                if (fileElement.files.length > 0) {
                    let file = fileElement.files[0];
                    let formData = new FormData();
                    formData.set('files', file);
                    Axios.post(props.URL, formData,
                    {
                        headers: {
                            "x-access-token": localStorage.getItem('token'),
                            "Content-Type": "multipart/form-data",
                        },
                    }).then((response) => {
                        setFlag(false)
                        if (response.data.error){
                            toast.error(response.data.message);
                        } else {
                            toast.success(response.data.message);
                            props.refresh()
                        }
                    }); 
                } else {
                    toast.error(`Elija un documento para subir con formato png o jpg`)
                    setFlag(false)
                }
            }
        } catch (error) {}
    }

    return(
        <>
            <Form.Control id={props.inputId} type="file" 
                onChange={()=>{
                    try {
                        setFileElement(document.getElementById(props.inputId));   
                    } catch (error) {
                        setFileElement('');
                    }
                }}
            />
            <p/>
            <Button disabled={flag} size="sm" variant="success" onClick={()=>{setFlag(true);submitDocument()}}>{(flag)?'Subiendo...':'Subir'}</Button>
            {
                (props.both) ?
                <>
                    <Button size="sm" variant="secondary" onClick={()=>{props.cancel()}}>Cancelar</Button>
                </>:<>
                </>
            }
        </>
    );
}