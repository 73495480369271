import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Form, Button, Modal } from 'react-bootstrap'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function EditarCantidadUsuarios(props) {

    const [nombre, setNombre] = useState('')
    const [cantidad, setCantidad] = useState('')
    const [vigencia, setVigencia] = useState('')

    const handleClose = () => {
        props.handleClose()
        props.handleId('')
        setVigencia('')
        setCantidad('')
        setNombre('')
    }

    useEffect(()=>{
        if(props.id){
            axios.get('/api/get/empresa-informacion/entidad',
            {params: {id: props.id}, headers: {"x-access-token": localStorage.getItem('tokenAdminSys')}})
            .then((response)=>{
                setNombre((response.data.result[0].nombre)?response.data.result[0].nombre:'')
                setCantidad((response.data.result[0].cantidad)?response.data.result[0].cantidad:'')
                setVigencia((response.data.result[0].vigencia)?response.data.result[0].vigencia.substring(0,10):'')
            })
        }
    },[props.id])

    const guardar = () => {
        axios.post('/api/editar/empresa_informacion', {
            id: props.id,
            cantidad: cantidad,
            vigencia: vigencia,
        }, {headers: {"x-access-token": localStorage.getItem('tokenAdminSys')}})
        .then((response)=>{
            if(response.data.err){
                toast.error('Error al editar esta institución')
            } else {
                toast.success('¡Exito!')
                handleClose()
                props.refresh()
            }
        })
    }

    const editarCantidad = (cantidad) => {
        if(/^[0-9]*$/.test(cantidad)){
            if(cantidad.charAt(0) === '0'){} else {
                setCantidad(cantidad)
            }
        }
    }

    return (
        <>
        <Modal show={props.show} onHide={handleClose} centered size="lg">
            <Modal.Header>
                <Modal.Title>
                    Editar información de institución
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Nombre de institución</Form.Label>
                        <Form.Control value={nombre} disabled/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Cantidad de usuarios</Form.Label>
                        <Form.Control value={cantidad} onChange={(e)=>editarCantidad(e.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Fecha de caducidad de vigencia</Form.Label>
                        <Form.Control type='date' value={vigencia} onChange={(e)=>setVigencia(e.target.value)}/>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={guardar} >
                    Guardar
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    )

}

export default EditarCantidadUsuarios