import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Table, OverlayTrigger, Tooltip, InputGroup } from 'react-bootstrap';
import { AiOutlinePlus, AiFillEdit, AiFillDelete, AiTwotoneAppstore, AiOutlineSearch } from 'react-icons/ai';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import './formulario.css'
import { CrearPeriodos } from "./crearPeriodos";
import PopUpBorrar from '../../Components/popUpBorrar'


// HTTP Requests
import Axios from 'axios';

toast.configure()

export const Dependencias = (props) => {

    /**
     * Lista de dependencias
     */
    const [listaPrinDep, setListaPrinDep] = useState([]);
    const [listaDep, setListaDep] = useState([]);


    const [listaEliminar, setListaEliminar] = useState([]);
    
    /*modal del popup borrar */
    const [id, setId] = useState(null);
    const [nombreDep, setNombreDep] = useState(null);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState(null);

    // Handle the displaying of the modal based on type and id
    const showDeleteModal = (id,nombre) => {
        setId(id);
        setNombreDep(nombre)
        setDeleteMessage(`¿Está seguro de borrar la dependencia ${nombre} ?`);
        setDisplayConfirmationModal(true);
    };

    // Hide the modal
    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };

    /**
     * Modals de dependencias
     */
    
    const [showState, setShowState] = useState(false);
    const [showNewPeriodo, setNewPeriodo] = useState(false);
    const [showStateUpdate, setShowStateUpdate] = useState(false);
    const [codigoEntidad, setCodigoEntidad] = useState('');

    const busquedaDependencia = (listaInfo) => {
        const input1 = document.getElementById('input-1').value;
        const input2 = document.getElementById('input-2').value;
        const input3 = document.getElementById('input-3').value;
        let tmpArray = []
        if (String(input1) === '' && String(input2) === '' && String(input3) === ''){
            return listaInfo
        } else {
            const indexes = listaInfo.map(
                    (element, index) => {
                            let flag = false;

                            if (String(input1) !== ''){
                                if(element.NOMBRE.toLowerCase().includes(input1.toLowerCase())){
                                    flag = true;
                                } else {
                                    return -1
                                }
                            }

                            if (String(input2) !== ''){
                                if(element.DESCRIPCION.toLowerCase().includes(input2.toLowerCase())){
                                    flag = true;
                                } else {
                                    return -1
                                }
                            }

                            if (String(input3) !== ''){
                                if(element.SIGLAS.toLowerCase().includes(input3.toLowerCase())){
                                    flag = true;
                                } else {
                                    return -1
                                }
                            }

                            if(flag){
                                return index
                            }
                            
                        }
                    ).filter(value => value >= 0)
            for (let i of indexes) {
                tmpArray.push(listaInfo[i])
            }
            return tmpArray
        }
    }

    const refreshDependencia = (param) => {
        Axios.get("/api/get/dependencia",{headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=> {
            setListaPrinDep(response.data);
            setListaDep(response.data);
        });
    }

    const submitDependencia = () => {
        if(props.nombreDep !== '' && props.siglasDep !== ''){
            Axios.post("/api/insert/dependencia", {
                nombreDep: props.nombreDep,
                descrDep: props.descrDep, 
                siglasDep: props.siglasDep,
                codigo_padre: codigoEntidad,
                codigo_tipo_entidad: 1,
            },{headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=>{
                if(response.data.result){
                    props.setSiglasDep('')
                    props.setNombreDep('')
                    props.setDescrDep('')
                    setShowState(false);
                    refreshDependencia(codigoEntidad)
                }else{
                    toast.error(response.data.error);
                }
            });
        } else {
            toast.error('Ingrese al menos el nombre y siglas de la dependencia (8 caracteres máximo para las siglas)')
        }
    };

 
    useEffect(() => {
        Axios.get('/api/various/getEntity', 
            {headers:{"x-access-token": localStorage.getItem('token')}}).then((response) =>{
                setCodigoEntidad(response.data[0].codigo_entidad);
                refreshDependencia(response.data[0].codigo_entidad)
        });
    }, []);

    useEffect(() => {
        if (codigoEntidad !== ''){
            Axios.get("/api/getdepwithchildren/"+String(codigoEntidad),
                {headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=> {
                setListaEliminar(response.data);
            });
        }
    }, [listaDep]);
  
    const deleteDependencia = (codigo) => {
        Axios.delete('/api/delete/dependencia/' + String(codigo), {headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=>{
            if (response.data.result === 'Error'){
              toast.error('Error al eliminar la dependencia: ' + nombreDep)
            } else {
                toast.info('Dependencia borrada con éxito: ' + nombreDep)
                refreshDependencia(codigoEntidad)
            }
        });
        setDisplayConfirmationModal(false);
    };
  
    
    const updateDependencia = () => {
        if(props.nombreDep !== '' && props.siglasDep !== ''){
            Axios.put('/api/update/dependencia/'+String(props.idDep), {
                nombreDep: props.nombreDep,
                descrDep: props.descrDep, 
                siglasDep: props.siglasDep,
                },{headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=>{
                    if (response.data.result === 'Error'){
                    toast.error('Error al actualizar la dependencia: ' + props.nombreDep)
                    } else {
                        refreshDependencia(codigoEntidad)
                    }
            });
        } else {
            toast.error('Ingrese al menos el nombre y siglas de la dependencia (8 caracteres máximo para las siglas)')
        }
    };

    return (
        <div className="container">
            {/**
             * Titulos principales de la página
             */}
            <h2>Dependencia</h2 >
            <p className="lead blog-description">Dependencias de la organización</p>
            
            {/**
             * Botón para crear nueva dependencia
             */}
            <Button size='sm' variant="success" className="nueva-dependencia" onClick={() => {
                props.setNombreDep('');
                props.setDescrDep('');
                props.setSiglasDep(''); 
                props.setIdDep('');
                setShowState(true);}}>
                <AiOutlinePlus/> Nueva dependencia
            </Button>
            {/**
             * Botón para crear nuevos periodos
            
            */}
            <CrearPeriodos showNewPeriodo={showNewPeriodo} setNewPeriodo={setNewPeriodo} />
            {/** 
             * Primer Modal
             * Este modal es para el formulario de una nueva dependencia
            */}
            <Modal show={showState} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Agregar una dependencia nueva
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Nombre de la dependencia</Form.Label>
                            <Form.Control onChange={(e)=> {props.setNombreDep(e.target.value);}} placeholder="Nombre de la dependencia" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Descripción de la dependencia</Form.Label>
                            <Form.Control onChange={(e)=> {props.setDescrDep(e.target.value)}} placeholder="Descripción de la dependencia" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Siglas</Form.Label>
                            <Form.Control onChange={(e)=> {props.setSiglasDep(e.target.value);}} placeholder="Siglas" />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {submitDependencia();}}>Guardar</Button>
                    <Button variant='secondary' onClick={()=>{setShowState(false);}}>Cancelar</Button>
                </Modal.Footer>
            </Modal>
            <p/>
            {/**
             * Esta tabla es para hacer display de todas las dependencias existentes
             */}
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Opción</th>
                        <th>Nombre</th>
                        <th>Descripción</th>
                        <th>Siglas</th>
                    </tr>
                    <tr>
                        <td>Herramientas de busqueda</td>
                        <td>
                            <InputGroup className="search-input">
                                <Button 
                                    variant="outline-secondary"
                                    onClick={()=>{
                                        setListaDep(busquedaDependencia(listaPrinDep));
                                    }}
                                >
                                    <AiOutlineSearch/>
                                </Button>
                                <Form.Control
                                    onChange={()=>{
                                        setListaDep(busquedaDependencia(listaPrinDep));
                                    }}
                                    id="input-1"
                                    type="text"
                                    placeholder="busqueda por nombre"
                                />
                            </InputGroup>
                        </td>
                        <td>
                            <InputGroup className="search-input">
                                <Button 
                                    variant="outline-secondary"
                                    onClick={()=>{
                                        setListaDep(busquedaDependencia(listaPrinDep));
                                    }}
                                >
                                    <AiOutlineSearch/>
                                </Button>
                                <Form.Control
                                    onChange={()=>{
                                        setListaDep(busquedaDependencia(listaPrinDep));
                                    }}
                                    id="input-2"
                                    type="text"
                                    placeholder="busqueda por descripción"
                                />
                            </InputGroup>
                        </td>
                        <td>
                            <InputGroup className="search-input">
                                <Button 
                                    variant="outline-secondary"
                                    onClick={()=>{
                                        setListaDep(busquedaDependencia(listaPrinDep));
                                    }}
                                >
                                    <AiOutlineSearch/>
                                </Button>
                                <Form.Control
                                    onChange={()=>{
                                        setListaDep(busquedaDependencia(listaPrinDep));
                                    }}
                                    id="input-3"
                                    type="text"
                                    placeholder="busqueda por siglas"
                                />
                            </InputGroup>
                        </td>
                    </tr> 
                </thead>
                <tbody>
                    {listaDep.map((value) => {
                        return (
                        <tr key={value.CODIGO}>
                            <td>
                            <Button className="segundo-boton" size="sm" variant="info" onClick={() => {
                                props.setNombreDep(value.NOMBRE);
                                props.setDescrDep(value.DESCRIPCION);
                                props.setSiglasDep(value.SIGLAS); 
                                setShowStateUpdate(true);
                                props.setIdDep(value.CODIGO);
                                }}>
                                    <AiFillEdit/>    Editar
                            </Button>
                            {
                                (props.usuarioWeb === value.USUARIO_CREACION)?
                                    <Button className="segundo-boton" size="sm" variant="danger" 
                                    onClick={() => showDeleteModal(value.CODIGO,value.NOMBRE)}
                                    >
                                        <AiFillDelete/>    Eliminar
                                    </Button> : 
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={<Tooltip id="button-tooltip-1">No tiene autorización para eliminar esta dependencia</Tooltip>}
                                    >
                                        <span>
                                            <Button className="segundo-boton" size="sm" variant="danger" disabled>
                                                <AiFillDelete/>    Eliminar
                                            </Button>
                                        </span>
                                    </OverlayTrigger>
                            }
                            </td>
                            <td>{value.NOMBRE}</td>
                            <td>{value.DESCRIPCION}</td>
                            <td>{value.SIGLAS}</td>
                        </tr>
                        ); 
                    })}
                </tbody>
            </Table>
            <Modal show={showStateUpdate} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Editar dependencia
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Nombre de la dependencia</Form.Label>
                            <Form.Control onChange={(e)=> {props.setNombreDep(e.target.value);}} placeholder="Nombre de la dependencia" defaultValue={props.nombreDep}/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Descripción de la dependencia</Form.Label>
                            <Form.Control onChange={(e)=> {props.setDescrDep(e.target.value)}} placeholder="Descripción de la dependencia" defaultValue={props.descrDep}/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Siglas</Form.Label>
                            <Form.Control disabled onChange={(e)=> {props.setSiglasDep(e.target.value);}} placeholder="Siglas" defaultValue={props.siglasDep}/>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {updateDependencia();setShowStateUpdate(false);}}>Guardar</Button>
                    <Button variant='secondary' onClick={()=>{
                        setShowStateUpdate(false);
                        props.setNombreDep('');
                        props.setDescrDep('');
                        props.setSiglasDep(''); 
                        props.setIdDep('');}}>Cancelar</Button>
                </Modal.Footer>
            </Modal>    
            
            <PopUpBorrar showModal={displayConfirmationModal}
                confirmModal={deleteDependencia} hideModal={hideConfirmationModal}
                id={id} message={deleteMessage} />        
        </div>
    );
}    

