import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Table, OverlayTrigger, Tooltip, InputGroup } from 'react-bootstrap';
import { AiOutlinePlus, AiFillEdit, AiFillDelete, AiTwotoneAppstore, AiOutlineSearch } from 'react-icons/ai';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import '../Dependencias/formulario.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PopUpBorrar from '../../Components/popUpBorrar'

// HTTP Requests
import Axios from 'axios';

toast.configure()

export const Periodo = (props) => {

    /**
     * Lista de dependencias
     */
    const [startDate, setStartDate] = useState(new Date());
    const [listaPrinPeriodo, setListaPrinPeriodo] = useState([]);
    const [listaPeriodo, setListaPeriodo] = useState([]);


    const [listaEliminar, setListaEliminar] = useState([]);

    /*modal del popup borrar */
    const [id, setId] = useState(null);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState(null);

    // Handle the displaying of the modal based on type and id
    const showDeleteModal = (id) => {
        setId(id);
        setDeleteMessage(`¿Está seguro de borrar el período ${id} ?`);
        setDisplayConfirmationModal(true);
    };

    // Hide the modal
    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };


    const [showState, setShowState] = useState(false);
    const [showStateUpdate, setShowStateUpdate] = useState(false);
    const [codigoEntidad, setCodigoEntidad] = useState('');

    const busquedaPeriodo = (listaInfo) => {
        const input1 = document.getElementById('input-1').value;
        let tmpArray = []
        if (String(input1) === '') {
            return listaInfo
        } else {
            const indexes = listaInfo.map(
                (element, index) => {
                    let flag = false;

                    if (String(input1) !== '') {
                        if (element.CODIGO.toLowerCase().includes(input1.toLowerCase())) {
                            flag = true;
                        } else {
                            return -1
                        }
                    }

                    if (flag) {
                        return index
                    }

                }
            ).filter(value => value >= 0)
            for (let i of indexes) {
                tmpArray.push(listaInfo[i])
            }
            return tmpArray
        }
    }

    const refreshPeriodo = (param) => {
        Axios.get("/api/get/periodos", { headers: { "x-access-token": localStorage.getItem('token') } }).then((response) => {
            setListaPrinPeriodo(response.data);
            setListaPeriodo(response.data);
        });
    }

    const submitPeriodo = () => {
        if (props.idPeriodo !== '') {
            Axios.post("/api/insert/nuevo-periodo", {
                idPeriodo: props.idPeriodo,
            }, { headers: { "x-access-token": localStorage.getItem('token') } }).then((response) => {
                if (response.data.result) {
                    props.setIdPeriodo('')
                    setShowState(false);
                    refreshPeriodo(codigoEntidad)
                } else {
                    toast.error(response.data.error);
                }
            });
        } else {
            toast.error('Llene todos los campos')
        }
    };


    useEffect(() => {
        Axios.get('/api/various/getEntity',
            { headers: { "x-access-token": localStorage.getItem('token') } }).then((response) => {
                setCodigoEntidad(response.data[0].codigo_entidad);
                refreshPeriodo(response.data[0].codigo_entidad)
            });
    }, []);


    const deletePeriodo = (codigo) => {
        Axios.delete('/api/delete/periodos/' + String(codigo), { headers: { "x-access-token": localStorage.getItem('token') } }).then((response) => {
            if (response.data.result === 'Error') {
                toast.error('Error al eliminar período: ' + codigo)
            } else {
                toast.info('Se ha eliminado el período ' + codigo)
                refreshPeriodo(codigoEntidad)
            }
        });
        setDisplayConfirmationModal(false);
    };

    return (
        <div className="container">
            {/**
             * Titulos principales de la página
             */}
            <h2>Períodos</h2 >
            <p className="lead blog-description">Períodos de la organización</p>

            {/**
             * Botón para crear nueva dependencia
             */}
            <Button size='sm' variant="success" className="nueva-dependencia" onClick={() => {
                props.setIdPeriodo('');
                setShowState(true);
            }}>
                <AiOutlinePlus /> Nuevo período
            </Button>
            {/** 
             * Primer Modal
             * Este modal es para el formulario de una nueva dependencia
            */}
            <Modal show={showState} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Agregar período
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Año del período</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <DatePicker
                                selected={props.idPeriodo}
                                onChange={(date) => props.setIdPeriodo(date)}
                                showYearPicker
                                dateFormat="yyyy"
                                yearItemNumber={8}
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>El período se toma a partir del 1 de enero hasta el 31 de diciembre del año seleccionado.</Form.Label>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { submitPeriodo() }}>Guardar</Button>
                    <Button variant='secondary' onClick={() => { setShowState(false); }}>Cancelar</Button>
                </Modal.Footer>
            </Modal>
            <p />
            {/**
             * Esta tabla es para hacer display de todos los períodos existentes
             */}
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Opción</th>
                        <th>Año</th>
                        <th>Fecha inicial</th>
                        <th>Fecha final</th>
                    </tr>
                    <tr>
                        <td>Herramientas de búsqueda</td>
                        <td>
                            <InputGroup className="search-input">
                                <Button
                                    variant="outline-secondary"
                                    onClick={() => {
                                        setListaPeriodo(busquedaPeriodo(listaPrinPeriodo));
                                    }}
                                >
                                    <AiOutlineSearch />
                                </Button>
                                <Form.Control
                                    onChange={() => {
                                        setListaPeriodo(busquedaPeriodo(listaPrinPeriodo));
                                    }}
                                    id="input-1"
                                    type="text"
                                    placeholder="búsqueda por código"
                                />
                            </InputGroup>
                        </td>
                        <td>
                            <InputGroup className="search-input">
                                <Button
                                    variant="outline-secondary"
                                    onClick={() => {
                                        setListaPeriodo(busquedaPeriodo(listaPrinPeriodo));
                                    }}
                                >
                                    <AiOutlineSearch />
                                </Button>
                                <Form.Control
                                    onChange={() => {
                                        setListaPeriodo(busquedaPeriodo(listaPrinPeriodo));
                                    }}
                                    id="input-2"
                                    type="text"
                                    placeholder="búsqueda por descripción"
                                />
                            </InputGroup>
                        </td>
                        <td>
                            <InputGroup className="search-input">
                                <Button
                                    variant="outline-secondary"
                                    onClick={() => {
                                        setListaPeriodo(busquedaPeriodo(listaPrinPeriodo));
                                    }}
                                >
                                    <AiOutlineSearch />
                                </Button>
                                <Form.Control
                                    onChange={() => {
                                        setListaPeriodo(busquedaPeriodo(listaPrinPeriodo));
                                    }}
                                    id="input-3"
                                    type="text"
                                    placeholder="búsqueda por descripción"
                                />
                            </InputGroup>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {listaPeriodo.map((value) => {
                        return (
                            <tr key={value.CODIGO_PERIODO}>
                                <td>
                                    {
                                        (props.usuarioWeb === value.USUARIO_CREACION) ?
                                            <Button className="segundo-boton" size="sm" variant="danger"
                                                onClick={() => showDeleteModal(value.CODIGO_PERIODO)}
                                            >
                                                <AiFillDelete />    Eliminar
                                            </Button> :
                                            <OverlayTrigger
                                                placement="right"
                                                overlay={<Tooltip id="button-tooltip-1">No tiene autorización para eliminar este período</Tooltip>}
                                            >
                                                <span>
                                                    <Button className="segundo-boton" size="sm" variant="danger" disabled>
                                                        <AiFillDelete />    Eliminar
                                                    </Button>
                                                </span>
                                            </OverlayTrigger>


                                    }
                                </td>
                                <td>{value.CODIGO_PERIODO}</td>
                                <td>{value.FECINI}</td>
                                <td>{value.FECFIN}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <PopUpBorrar showModal={displayConfirmationModal}
                confirmModal={deletePeriodo} hideModal={hideConfirmationModal}
                id={id} message={deleteMessage} />    
        </div>
    );
}

