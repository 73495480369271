import React, { useState, useEffect } from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import axios from 'axios';
import { Button } from 'react-bootstrap';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ReportePlanDeTrabajo = ({ riesgo }) => {
    const [eventos, setEventos] = useState([]);
    const [riesgoData, setRiesgoData] = useState({});
    const [imageData, setImageData] = useState('');

    useEffect(() => {
        fetchRiesgoById();
        fetchEventos();
        fetchImage();
    }, [riesgo]);

    const fetchRiesgoById = async () => {
        if (riesgo) {
            try {
                const response = await axios.get('/api/getRiesgoById', {
                    headers: { "x-access-token": localStorage.getItem('token') },
                    params: {
                        codigoCia: riesgo.CODIGO_CIA,
                        codigoPeriodo: riesgo.CODIGO_PERIODO,
                        codigoTipoObjetivo: riesgo.CODIGO_TIPO_OBJETIVO,
                        codigoObjetivo: riesgo.CODIGO_OBJETIVO,
                        codigoArea: riesgo.CODIGO_AREA,
                        codigoRiesgo: riesgo.CODIGO_RIESGO
                    }
                });
                setRiesgoData(response.data);
                console.log("Datos obtenidos:", response.data);
            } catch (error) {
                console.error("Error fetching data", error);
            }
        }
    };

    const fetchEventos = async () => {
        if (riesgo) {
            try {
                const response = await axios.get(`/api/get/eventos/${riesgo.CODIGO_CIA}/${riesgo.CODIGO_PERIODO}/${riesgo.CODIGO_TIPO_OBJETIVO}/${riesgo.CODIGO_OBJETIVO}/${riesgo.CODIGO_AREA}/${riesgo.CODIGO_RIESGO}`, {
                    headers: { "x-access-token": localStorage.getItem('token') }
                });
                setEventos(response.data.result || []);
            } catch (error) {
                console.error("Error fetching eventos", error);
                setEventos([]);
            }
        }
    };

    const fetchImage = async () => {
        try {
            const response = await axios.get('/api/get/image-url', {
                headers: { "x-access-token": localStorage.getItem('token') }
            });
            const imageUrl = 'http://localhost:3001/Pictures/' + response.data.result[0].path;
            const imageResponse = await axios.get(imageUrl, { responseType: 'blob' });
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageData(reader.result);
            };
            reader.readAsDataURL(imageResponse.data);
        } catch (error) {
            console.error("Error fetching image", error);
        }
    };

    const getNivelTolerancia = (codigoTolerancia) => {
        switch (codigoTolerancia) {
            case 1:
                return { text: 'No Tolerable', color: '#CD1719' }; // Rojo
            case 2:
                return { text: 'Gestionable', color: '#FFED00' }; // Amarillo
            case 3:
                return { text: 'Tolerable', color: '#3FA535' }; // Verde
            default:
                return { text: 'Desconocido', color: 'gray' };
        }
    };

    const getSeveridadRiesgo = (codigoSeveridad) => {
        switch (codigoSeveridad) {
            case 1:
                return { text: 'Baja', color: '#3FA535' }; // Verde
            case 2:
                return { text: 'Media', color: '#FFED00' }; // Amarillo
            case 3:
                return { text: 'Alta', color: '#CD1719' }; // Rojo
            default:
                return { text: 'Desconocido', color: 'gray' };
        }
    };

    const getRiesgoResidualColor = (riesgoResidual) => {
        if (riesgoResidual <= 10) {
            return '#3FA535'; // Verde
        } else if (riesgoResidual <= 15) {
            return '#FFED00'; // Amarillo
        } else {
            return '#CD1719'; // Rojo
        }
    };

    const generatePdf = () => {
        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleDateString();
        const formattedTime = currentDate.toLocaleTimeString();
    
        const docDefinition = {
            pageOrientation: 'landscape',
            pageMargins: [30, 70, 30, 30],
            header: function(currentPage, pageCount) {
                return {
                    columns: [
                        {
                            image: imageData,
                            width: 50
                        },
                        {
                            alignment: 'right',
                            stack: [
                                { text: `Página ${currentPage.toString()} de ${pageCount}`, fontSize: 10 },
                                { text: formattedDate, fontSize: 10 },
                                { text: formattedTime, fontSize: 10 },
                            ]
                        }
                    ],
                    margin: [30, 10]
                };
            },
            content: [
                {
                    text: 'Reporte de Plan de trabajo del Riesgo',
                    style: 'header',
                    alignment: 'center',
                    margin: [0, 0, 0, 10]
                },
                {
                    style: 'tableExample',
                    table: {
                        widths: [200, '*'],
                        body: [
                            [{ text: 'Entidad', style: 'tableHeader' }, { text: 'DEMO', style: 'tableData' }],
                            [{ text: 'Periodo de evaluación', style: 'tableHeader' }, { text: riesgoData.CODIGO_PERIODO, style: 'tableData' }],
                            [{ text: 'Tipo Objetivo', style: 'tableHeader' }, { text: riesgoData.DESCRIPCION_TIPO_OBJETIVO, style: 'tableData' }],
                            [{ text: 'Objetivo', style: 'tableHeader' }, { text: riesgoData.DESCRIPCION_OBJETIVO, style: 'tableData' }],
                            [{ text: 'Área', style: 'tableHeader' }, { text: riesgoData.DESCRIPCION_AREA, style: 'tableData' }]
                        ]
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 1;
                        },
                        vLineWidth: function (i) {
                            return 1;
                        },
                        hLineColor: function (i) {
                            return i === 1 ? 'black' : 'black';
                        },
                        vLineColor: function () {
                            return 'black';
                        },
                    },
                    margin: [0, 15, 0, 10]
                },
                {
                    table: {
                        headerRows: 1, // Esta propiedad asegura que la primera fila de la tabla (los encabezados) se repitan en cada página.
                        widths: [50, 55, 100, 100, 150, 125, 60, 60],
                        body: [
                            [
                                { text: 'Ref.\nTipo\nRiesgo', style: 'tableHeader', alignment: 'center', fillColor: '#2a3f54', color: 'white' },
                                { text: 'Nivel de\nRiesgo\nResidual', style: 'tableHeader', alignment: 'center', fillColor: '#2a3f54', color: 'white' },
                                { text: 'Riesgo', style: 'tableHeader', alignment: 'center', fillColor: '#2a3f54', color: 'white' },
                                { text: 'Controles para Implementación', style: 'tableHeader', alignment: 'center', fillColor: '#2a3f54', color: 'white' },
                                { text: 'Recursos Internos o Externos', style: 'tableHeader', alignment: 'center', fillColor: '#2a3f54', color: 'white' },
                                { text: 'Puesto Responsable', style: 'tableHeader', alignment: 'center', fillColor: '#2a3f54', color: 'white' },
                                { text: 'Fecha Inicio', style: 'tableHeader', alignment: 'center', fillColor: '#2a3f54', color: 'white' },
                                { text: 'Fecha Final', style: 'tableHeader', alignment: 'center', fillColor: '#2a3f54', color: 'white' }
                            ],
                            ...eventos.map((evento, index) => {
                                const nivelTolerancia = getNivelTolerancia(evento.CODIGO_TOLERANCIA);
                                const severidadRiesgo = getSeveridadRiesgo(evento.CODIGO_SEVERIDAD);
                                const riesgoResidualColor = getRiesgoResidualColor(riesgoData.RIESGO_RESIDUAL);
                                return [
                                    { text: ` ${riesgoData.CODIGO_TIPO_OBJETIVO} - ${riesgo.CODIGO_RIESGO}`, rowSpan: eventos.length, style: 'tableCell', alignment: 'center', margin: [0, eventos.length * 12, 0, 0] },
                                    { text: riesgoData.RIESGO_RESIDUAL, style: 'tableCell', alignment: 'center', rowSpan: eventos.length, fillColor: riesgoResidualColor, color: 'white', margin: [0, eventos.length * 12, 0, 0] },
                                    { text: riesgoData.DESCRIPCION_AREA, style: 'tableCell', alignment: 'center', margin: [0, 5, 0, 5] },
                                    { text: evento.CONTROL_IMPL, style: 'tableCell', alignment: 'left', margin: [0, 5, 0, 5] },
                                    { text: evento.METODO_MONITOREO, style: 'tableCell', alignment: 'left', margin: [0, 5, 0, 5] },
                                    { text: evento.RESPONSABLE, style: 'tableCell', alignment: 'left', margin: [0, 5, 0, 5] },
                                    { text: evento.FECHA_INICIO, style: 'tableCell', alignment: 'center', margin: [0, 5, 0, 5] },
                                    { text: evento.FECHA_FINAL, style: 'tableCell', alignment: 'center', margin: [0, 5, 0, 5] }
                                ];
                            })
                        ]
                    },
                    margin: [0, 30, 0, 10]
                },
                {
                    style: 'tableFooter',
                    table: {
                        widths: ['*', '*'],
                        body: [
                            [
                                {
                                    text: 'Responsable de la elaboración de la matriz (nombre y puesto):',
                                    style: 'footerHeader',
                                    fillColor: '#2a3f54', // Fondo azul solo para esta celda
                                    margin: [3, 3, 3, 3]
                                },
                                {
                                    text: '', // Espacio para la firma
                                    fillColor: 'white', // Fondo blanco para esta celda
                                    margin: [3, 3, 3, 60] // Margen inferior para dejar espacio para la firma
                                }
                            ]
                        ]
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 1 : 1;
                        },
                        vLineWidth: function (i) {
                            return 1;
                        },
                        hLineColor: function (i) {
                            return 'black';
                        },
                        vLineColor: function () {
                            return 'black';
                        },
                    },
                    margin: [0, 45, 0, 10] // Ajuste del margen superior para mantener el espaciado general
                }
            ],
            styles: {
                header: {
                    fontSize: 22,
                    bold: true,
                    margin: [0, 20, 0, 10]
                },
                subheader: {
                    fontSize: 14,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 12,
                    color: 'white',
                    fillColor: '#2a3f54',
                    margin: [3, 3, 3, 3]
                },
                tableData: {
                    fontSize: 12,
                    color: 'black',
                    margin: [3, 3, 3, 3]
                },
                tableCell: {
                    fontSize: 10
                },
                tableExample: {
                    margin: [0, 20, 0, 10]
                },
                tableFooter: {
                    margin: [0, 30, 0, 10]
                },
                footerHeader: {
                    bold: true,
                    fontSize: 12,
                    color: 'white',
                    fillColor: '#2a3f54',
                    margin: [3, 3, 3, 3],
                    height: 120
                }
            }
        };
    
        pdfMake.createPdf(docDefinition).download('reporte_plan_de_trabajo.pdf');
    };
    
    return (
        <div>
            <Button variant="secondary" onClick={generatePdf}>
                Generar Reporte Plan de Trabajo
            </Button>
        </div>
    );
};

export default ReportePlanDeTrabajo;
