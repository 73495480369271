import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button } from 'react-bootstrap';
import '../../../styles/RiesgoMonitorStyle.scss';

const RiesgosMonitoreo = ({ codigoCia, codigoRiesgo }) => {
    const [data, setData] = useState([]);
    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('/api/historialMonitoreo', {
                    headers: { "x-access-token": localStorage.getItem('token') },
                    params: {
                        codigoCia,
                        codigoRiesgo
                    }
                });
                console.log(response.data);
                if (Array.isArray(response.data)) {
                    setData(response.data);
                } else if (response.data && typeof response.data === 'object') {
                    setData([response.data]); // Convertir objeto en arreglo
                } else {
                    console.error('Unexpected response data:', response.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [codigoCia, codigoRiesgo]);

    const handleShowAll = () => {
        setShowAll(true);
    };

    return (
        <div>
            <h6>Histórico de Monitoreos</h6>
            <div style={{ overflowY: 'auto', maxHeight: '400px', overflowX: 'auto' }}>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>FECHA MONITOREO</th>
                            <th>OBSERVACIONES</th>
                            <th>Monitor</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.slice(0, showAll ? data.length : 3).map((monitoreo) => (
                            <tr key={monitoreo.CODIGO_MONITOREO}>
                                <td>{new Date(monitoreo.FECHA_MONITOREO).toLocaleDateString()}</td>
                                <td>{monitoreo.CODIGO_OBSERVACIONES || 'No hay observaciones'}</td>
                                <td>{`${monitoreo.NOMBRE} ${monitoreo.APELLIDO}`}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                {!showAll && data.length > 3 && (
                    <Button variant="primary" onClick={handleShowAll}>
                        Ver más
                    </Button>
                )}
            </div>
        </div>
    );
};

export default RiesgosMonitoreo;
