import React, { useState } from 'react'
import UpdatedNavBar from '../NavBar/UpdatedNavBar'
import { Button, Grid, Stack, TextField, Typography } from '@mui/material';
import Axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Perfil() {

    const [vieja, setVieja] = useState('')
    const [nueva, setNueva] = useState('')
    const [nuevaRep, setNuevaRep] = useState('')

    const actualizarContraseña = () => {
        if (nueva && nuevaRep && nueva !== nuevaRep) {
            toast.info('Ha ocurrido un error al enviar su solicitud.');
        } else if (!nueva || !nuevaRep || !vieja) {
            toast.info('Llene todos los campos.');
        } else {
            Axios.post('/api/post/actualizar-contra-usuario-perfil',
                { vieja: vieja, nueva: nueva },
                { headers: { "x-access-token": localStorage.getItem('token') } })
                .then((response) => {
                    if(response.data.result){
                        toast.success('Contraseña actualizada con exito.');
                    } else {
                        toast.info(response.data.msg)
                    }
                })
        }
    }

    return (
        <>
            <UpdatedNavBar />
            <div className="container-reportes">
                <h2>Perfil</h2 >
                <p className="lead blog-description">
                    Sección para cambiar de contraseña
                </p>
                <hr />
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField label='Contraseña vieja' sx={{ width: { xs: '100%', md: '50%' } }} onChange={(e) => setVieja(e.target.value)} value={vieja} type='password' />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack spacing={1}>
                            <TextField label='Contraseña nueva' sx={{ width: { xs: '100%', md: '50%' } }} onChange={(e) => setNueva(e.target.value)} value={nueva} type='password' />
                            {
                                nueva.length < 8 && <Typography variant='span' color={'red'}>La contraseña nueva debe ser de al menos 8 caracteres</Typography>
                            }
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack spacing={1}>
                            <TextField label='Repita contraseña nueva' sx={{ width: { xs: '100%', md: '50%' } }} onChange={(e) => setNuevaRep(e.target.value)} value={nuevaRep} type='password' />
                            {
                                (nueva && nuevaRep && nueva !== nuevaRep) && <Typography variant='span' color={'red'}>Las contraseñas no coinciden</Typography>
                            }
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant='contained' size='small' color='success' onClick={actualizarContraseña}>
                            Actualizar contraseña
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

export default Perfil