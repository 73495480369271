import React, { useState } from "react";
import './admin.css';
import { Tabs, Tab } from "react-bootstrap";
import { Stack, Typography } from "@mui/material";
//import AdminUsuarios from "./AdminUsuarios";
import RolesViews from "./RolesViews";
import UpdatedNavBar from "../NavBar/UpdatedNavBar";
import CambiarLogo from "./CambiarLogo";

function AdminView() {

    const [key, setKey] = useState('roles');
    const [refresh, setRefresh] = useState(0)

    return(
        <>
            <UpdatedNavBar/>
            <div className="container-dependencias">
                <Stack spacing={1}>
                    {/**
                     * Titulos principales de la página
                     */}
                    <Typography variant="h2" color='black' >Sitio de administración</Typography>
                    <Typography variant="h4" color='lightgray' >En este espacio podrá administrar los roles y y logotipo de su empresa</Typography>
                    <hr/>
                    <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3" >
                        {/*<Tab eventKey="usuarios" title="Usuarios">
                            <AdminUsuarios setRefresh={setRefresh} refresh={refresh}/>
                        </Tab>*/}
                        <Tab eventKey="roles" title="Roles">
                            <RolesViews refresh={refresh}/>
                        </Tab>
                        <Tab eventKey="logo" title="Logo de la empresa">
                            <CambiarLogo />
                        </Tab>
                    </Tabs>
                </Stack>
            </div>
        </>
    )
}

export default AdminView